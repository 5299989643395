/* media queries */
@media (max-width: 1300px) {
  .options {
    width: 50%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  #game {
    padding: 30px;
  }
  #result {
    padding: 60px 30px;
  }
}

@media (max-width: 1020px) {
  .options {
    width: 60%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 900px) {
  .options {
    width: 70%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 800px) {
  .options {
    width: 85%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  #game {
    padding: 30px 60px;
  }
}

@media (max-width: 680px) {
  .options {
    width: 95%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  #game {
    padding: 20px 60px;
  }
}

@media (max-width: 640px) {
  .options {
    width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 600px) {
  .option {
    width: 140px;
    height: 100px;
    margin: 15px auto;
    border-radius: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 35px 15px 15px 15px;
    text-transform: uppercase;
    cursor: pointer;
  }
  #home {
    padding: 55px 60px;
  }
  #game {
    padding: 40px 60px;
  }
  #home .logo,
  #game .logo {
    height: 35px;
  }
  .question {
    font-size: 18px;
    font-weight: 400;
  }
  .option p {
    font-size: 13px;
  }
  .score {
    opacity: 0;
    transition: opacity 0.3s ease-in;
    margin-top: 5px;
  }
  .score h2 {
    font-size: 16px;
  }
  .score p {
    font-size: 16px;
    margin-bottom: 0px;
  }
  .timer {
    margin-bottom: 20px;
  }
  .timer h2 {
    font-size: 18px;
  }
  .timer img {
    margin-right: 10px;
    height: 26px;
  }
}

@media (max-width: 480px) {
  #home {
    padding: 55px 30px;
  }
  #game {
    padding: 30px;
  }
  #home h2 {
    margin-top: 10px;
    font-size: 22px;
  }
  #home p,
  .get-in-touch {
    font-size: 18px;
  }
  #home button,
  .buttons button {
    font-size: 16px;
    padding: 10px 30px;
  }
  .result {
    font-size: 20px;
    padding-bottom: 15px;
  }
  .result-container p {
    font-size: 18px;
  }
  .question {
    font-size: 16px;
    font-weight: 500;
    text-align: start;
  }
  .question-container {
    padding: 15px;
    align-items: normal;
  }
}

@media (max-width: 380px) {
  .question {
    font-size: 16px;
    font-weight: 400;
  }
  .option {
    width: 120px;
    height: 120px;
    margin: 10px auto;
    border-radius: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 35px 15px 15px 15px;
    text-transform: uppercase;
    cursor: pointer;
  }
  .option p {
    font-size: 13px;
  }
  .score p {
    font-size: 14px;
  }
  .popup {
    width: 250px;
    height: 180px;
  }
  .popup p {
    font-size: 18px;
  }
  .popup button {
    font-size: 14px;
  }
  .result-container p {
    font-size: 18px;
  }
  #game {
    padding: 20px 30px;
  }
  .question-container {
    margin-bottom: 10px;
  }
  input {
    padding: 10px;
  }
  .options {
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 340px) {
  #game {
    padding: 30px 20px;
  }
  #result {
    padding: 60px 20px;
  }
  #home {
    padding: 55px 20px;
  }
}
