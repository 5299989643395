#result {
  padding: 50px 100px;
}

.result {
  font-size: 24px;
  padding-bottom: 20px;
  text-transform: uppercase;
}

.result-container {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.5s ease-in, transform 0.5s ease-in;
}

.result-container.visible {
  opacity: 1;
  transform: scale(1);
}

.result-container h3 {
  color: #f1efe3;
  text-shadow: 2px 2px 2px rgb(31, 32, 31);
}

.result-container p {
  font-size: 20px;
  color: rgb(53, 92, 6);
  font-weight: 500;
  margin-bottom: 10px;
}

.get-in-touch {
  font-size: 20px;
}

.buttons {
  display: grid;
  width: 180px;
  margin: 40px auto;
}

.buttons button {
  font-size: 16px;
  color: rgb(53, 92, 6);
  border: 2px rgb(53, 92, 6) solid;
  background: rgb(59, 222, 32);
  background: linear-gradient(
    200deg,
    rgb(84, 196, 65) 26%,
    rgb(252, 201, 62) 60%
  );
  font-weight: 600;
  padding: 13px 30px;
  border-radius: 10px;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 20px;
}

.buttons button:hover {
  transform: translateY(-5%);
}

.result-container img {
  height: 70px;
  margin-top: 40px;
}
