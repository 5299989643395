/* game */

.game-container {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.5s ease-in, transform 0.5s ease-in;
  margin-top: 15px;
}

.game-container.visible {
  opacity: 1;
  transform: scale(1);
}



/* overlay */

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  height: 200px;
  background: rgb(43, 141, 26);
  z-index: 9999;
  border-radius: 5px;
}

.popup p {
  margin-top: 40px;
  color: #ffda24;
  font-size: 20px;
}

.popup-content {
  background: rgb(43, 141, 26);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.popup button {
  padding: 10px 20px;
  width: 80%;
  background: #ffda24;
  color: rgb(27, 83, 17);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
}

.popup button:hover {
  opacity: 0.9;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  display: none;
}

.overlay.visible {
  display: block;
}
