
.home-container {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.5s ease-in, transform 0.5s ease-in;
}

.home-container.visible {
  opacity: 1;
  transform: scale(1);
}


 h2 {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 30px;
  font-weight: bold;
  color: #f1efe3;
  text-shadow: 2px 2px 2px rgb(31, 32, 31);
}

p {
  color: rgb(53, 92, 6);
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 500;
}

.HomeBtn {
  font-size: 16px;
  color: rgb(53, 92, 6);
  border: 2px rgb(53, 92, 6) solid;
  background: rgb(59, 222, 32);
  background: linear-gradient(
    200deg,
    rgb(84, 196, 65) 26%,
    rgb(252, 201, 62) 60%
  );
  padding: 13px 40px;
  border-radius: 10px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 1px 1px 1px #4b4a4a;
  font-weight: 600;
}

.HomeBtn:hover {
  transform: translateY(-5%);
}

input {
  display: flex;
  justify-content: center;
  margin: 0px auto 35px auto;
  padding: 15px 20px;
  border-radius: 8px;
  border: 2px solid green;
  outline: none;
  background-color: #ffda24;
  color: black;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
}

input::placeholder {
  color: rgb(53, 92, 6);
}
