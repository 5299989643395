#leaderboard {
  color: rgb(53, 92, 6);
  padding: 60px 100px;
}

.leaderboard-container {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.5s ease-in, transform 0.5s ease-in;
}

.leaderboard-container.visible {
  opacity: 1;
  transform: scale(1);
}

table {
  width: 40%;
  margin: 40px auto;
}

th {
  margin: 0px;
  padding: 20px 0px;
  background-color: #259711;
  color: rgb(248, 214, 121);
}

.buttons-btn {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  font-size: 10px;
  margin-bottom: 20px;
}

button {
  width: auto;
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 5px;
}

img {
  height: 55px;
}

tr {
  background-color: rgb(252, 201, 62);
}

td {
  padding: 15px 0px;
}

@media (max-width: 1200px) {
  table {
    width: 70%;
    margin: 40px auto;
  }
}

@media (max-width: 800px) {
  table {
    width: 90%;
    margin: 40px auto;
  }
}

@media (max-width: 600px) {
  table {
    width: 100%;
    margin: 40px auto;
  }
  td {
    padding: 10px 0px;
  }
}

@media (max-width: 500px) {
  #leaderboard {
    padding: 30px;
  }
  td {
    font-size: 15px;
    padding: 8px 0px;
  }
  th {
    font-size: 14px;
  }
  h1 {
    font-size: 20px;
  }
  table {
    margin: 20px auto;
  }
}

@media (max-width: 350px) {
  #leaderboard {
    padding: 40px 20px;
  }
}
