.question-container {
  padding: 10px 30px;
  font-weight: 500;
  color: rgba(15, 136, 25, 0.925);
  background-color: #ffda24;
  border: 1px solid rgba(15, 136, 25, 0.925);
  border-radius: 30px;
  box-shadow: 1px 1px 1px gray;
  width: fit-content;
  width: -moz-fit-content;
  opacity: 0.9;
}

.question-container p {
  margin-right: 5px;
}

.option-container {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
}

.options {
  width: 35%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.option {
  width: 190px;
  height: 100px;
  margin: 20px auto;
  background: rgb(43, 141, 26);
  opacity: 0.8;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px 20px 20px;
  text-transform: uppercase;
  cursor: pointer;
  box-sizing: border-box;
  border: 3px solid rgb(5, 102, 13);
}

.option p {
  color: #ffda24;
  font-weight: 600;
  font-size: 14px;
}

.score {
  color: rgb(5, 102, 13);
  opacity: 0;
  transition: opacity 0.3s ease-in;
  margin-top: 15px;
  font-weight: 500;
}

.question-number {
  opacity: 0;
}

.score.visible,
.question-number.visible {
  opacity: 1;
}

.score p {
  border-radius: 50%;
  font-family: sans-serif;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  margin: 15px auto;
  background-color: #ffda249d;
  box-shadow: rgba(17, 87, 17, 0.74) 1px 1px 9px 2px;
}

.option.correct {
  transition: border 0.4s ease-in;
  border: 2px solid rgb(9, 189, 9);
  box-shadow: rgba(17, 87, 17, 0.74) 1px 1px 9px 2px;
}

.option.wrong {
  transition: border 0.4s ease-in;
  border: 2px solid red;
  box-shadow: rgba(206, 93, 41, 0.74) 1px 1px 9px 2px;
}
